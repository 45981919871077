import styled, { createGlobalStyle } from 'styled-components';
import bk_mobile from './assets/bk_mobile.png'
import bk from './assets/bk.png'
import form_bk from './assets/form_bk.png'


export const GlobalStyle = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    list-style-type: none;
    box-sizing: border-box;
    outline: none;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
  }

  a {
      color: inherit;
      text-decoration: none;
  }
`;

export const Main = styled.main`
  position: relative;
  section.display{
    background-image: url(${bk_mobile}); 
    width: 100%;
    height: calc(100vw * 520 / 415);
    aspect-ratio: 415 / 520;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 4vw 0;
        img{
            width: 98px;
            aspect-ratio: 98 / 64;
        }
        .joinus{
            background-color: #000000;
            border-radius: 100px;
            padding: 12px 20px;
            display: flex;
            align-items: center;
            cursor: pointer;
            p{
                color: #FFFFFF;
                margin-right: 10px;
                font-size: 14px
            }
            svg{
                background-color: #DBD65C;
                border-radius: 20px;
                height: 12px;
                width: 12px;
            }
        }
    }
    .text{
        width: 74vw;
        margin: 0 0 0 10vw;
        position: absolute;
        bottom: 17vw;
        h1{
            color: #FFFFFF;
            font-size: 12vw;
            line-height: 12vw;
            margin: 0 0 3vw;
        }
        p{
            color: #FFFFFF;
            margin: 0 0 0 8vw;
            font-size: 3vw;
            line-height: 5vw;
            span.highlight{
                color: #DBD65C; 
            }
            span.gradient-underline{
                color: #DBD65C;
                text-decoration: none;
                position: relative;
                padding: 0 0 2px 0;
                &:after{
                    content: "";
                    position: absolute;
                    background-image: linear-gradient(to right, #DBD65C, #5614B0);
                    height: 2.5px;
                    width: 100%;
                    bottom: 0;
                    left: 0;
                }
            }
        }
    }
    .mouse{
        display: none;
    }
  }
  section.form{
    background-image: url(${form_bk});
    padding: 50px 0 70px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    h1{
        color: #FFFFFF;
        font-size: 32px;
        position: relative;
        padding: 0 0 5px;
        &:after{
            content: "";
            position: absolute;
            background-image: linear-gradient(to right, #DBD65C, #5614B0);
            height: 2px;
            width: 100px;
            bottom: 0;
            left: calc(50% - 50px);
        }
    }
    form{
        width: 340px;
        margin: 35px 0 0;
        label{
            color: #FFFFFFCC;
            font-size: 16px;
            line-height: 24px;
        }
        .cover{
            height: 33px;
            width: 100%;
            background-image: linear-gradient(to right, #DBD65C, #5614B0);
            border-radius: 100px;
            display: flex;
            align-items: center;
            color: #DBD65C;
            padding: 2px;
            margin: 8px 0 0;
            input{
                width: 100%;
                height: 100%;
                padding: 0 20px;
                border-radius: 100px 0 0 100px;
                background-color: #2A2E2E;
                color: white;
                border: none;
                font-size: 12px;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            input[type=number] {
                -moz-appearance: textfield;
            }
            .svg{
                border-left: thin solid #9191914A;
                border-radius: 0 100px 100px 0;
                background-color: #2A2E2E;
                height: 100%;
                padding: 0 5px 0 0;
                aspect-ratio: 1 / 1;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                svg{
                    width: 14px;
                    path{
                        stroke: #DBD65C;
                    }
                }
            }
        }

    }
    p{
        margin: 70px 0 0; 
        color: #919191;
        font-size: 12px;
        line-height: 30px;
        span{
            color: #DBD65C;
        }
    }
    .social-links{
        margin: 40px 0 0;
        width: 310px;
        display: flex;
        justify-content: space-between;
        color: #919191;
        svg{
            width: 30px;
            height: 30px;
            cursor: pointer;
        }
    }
  }
  footer{
      background-color: #000000;
      padding: 33px 0 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      img{
          width: 99px;
          height: 64px;
      }
      p{
          font-size: 12px;
          line-height: 30px;
          color: #919191;
          margin: 3px 0 0;
      }
  }
  .chat{
      position: absolute;
      bottom: 83px;
      right: 18px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      cursor: pointer;
      img{
          width: 46px;
          height: 46px;
      }
      p{
          display: none;
      }
  }
  @media only screen and (min-width: 900px) {
    section.display{
      background-image: url(${bk});
      height: calc(100vw * 1176 / 1920);
      aspect-ratio: 1920 / 1176;
      .top{
        padding: 45px 7vw 0;
        align-items: flex-start;
        img{
            width: 162px;
            aspect-ratio: 162 / 105;
        }
        .joinus{
            padding: 24px 35px;
            p{
                margin-right: 20px;
                font-size: 24px;
            }
            svg{
                height: 24px;
                width: 24px;
            }
        }
      }
      .text{
        margin: 0 0 0 21vw;
        width: 48vw;
        h1{
            font-size: 6vw;
            line-height: 6vw;
            margin: 0 0 24px;
            margin: 0 0 1.25vw;
        }
        p{
            margin: 0 0 0 8vw;
            font-size: 1.5vw;
            line-height: 1.9vw;
        }
      }
      .mouse{
        display: block;
        color: #FFFFFF;
        position: absolute;
        left: calc(50% - 11px);
        bottom: 7.3vw;
        svg{
            height: 35px;
            width: 22px;
        }
      }
    }
    section.form{
        padding: 100px 0 150px;
        h1{
            font-size: 72px;
        }
        form{
            width: 650px;
            margin: 55px 0 0;
            label{
                font-size: 28px;
                line-height: 38px;
            }
            .cover{
                height: 63px;
                margin: 13px 0 0;
                input{
                    font-size: 20px;
                }
                .svg{
                    padding: 0 10px;
                    svg{
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
        p{
            margin: 180px 0 0; 
            font-size: 20px;
            line-height: 30px;
        }
        .social-links{
        margin: 70px 0 0;
        width: 550px;
        svg{
            width: 60px;
            height: 60px;
            cursor: pointer;
        }
    }
    }
    footer{
      padding: 40px 0 33px;
      img{
          width: 162px;
          height: 105px;
      }
      p{
          font-size: 20px;
          line-height: 30px;
          margin: 12px 0 0;
      }
    }
    .chat{
      bottom: 40px;
      right: 7.3vw;
      position: fixed;
      img{
          width: 46px;
          height: 46px;
      }
      &:hover{
        p{
            display: block;
            margin: 16px 0 0;
            font-size: 24px;
            color: #FFFFFF;
            opacity: 80%;
        }
      }
    }

  }
`;
