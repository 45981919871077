import React, { useState } from 'react';
import { useForm, SubmitHandler } from "react-hook-form";
import emailjs from 'emailjs-com';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { GoChevronRight } from 'react-icons/go';
import { GrSend } from 'react-icons/gr';
import { RiTwitterLine, RiInstagramLine, RiSpotifyLine } from 'react-icons/ri';
import { FiYoutube, FiCompass } from 'react-icons/fi';
import { IoMusicalNotesOutline } from 'react-icons/io5';
import { BiMouse } from 'react-icons/bi';
import { Main } from './AppStyle'
import whiteLogo from './assets/logo_white.png'
import chat from './assets/chat.png'


function App() {

  const [sendingEmail, setSendingEmail] = useState(false)
  const [sendingPhone, setSendingPhone] = useState(false)
  const [phone, setPhone] = useState("")

  const EmailJS = (templateParams: any, setLoader: any) => {
    emailjs.send('dev_test', 'template_f1bod0m', templateParams, 'user_MxRphF1Mu8SEVB50XBkeE')
      .then((response) => {
        console.log({ response })
        console.log('SUCCESS!', response.status, response.text);
      }, (err) => {
        console.log('FAILED...', err);
      })
      .finally(() => {
        setLoader(false)
    });
  }

  const submitPhone = () => {
    setSendingPhone(true)
    EmailJS({ user: phone }, setSendingPhone)
  }


  return (
    <Main>
      <section className="display">
        <div className="top">
          <img src={whiteLogo} alt="dark kontinent logo" />
          <a href="#joinus">
            <div className="joinus">
              <p>Join Us</p>
              <GoChevronRight />
            </div>
          </a>
        </div>
        <div className="text">
          <h1>Beyond Borders.</h1>
          <p>Soundscapes that <span className="highlight">must</span> be experienced and stories that <span className="highlight">must</span> be heard, regardless of borders. <span className="gradient-underline">Sign up</span> now for the hottest erupture of melodies from the darkest corners of the planet. Coming to airwaves in your area soon.</p>
        </div>
        <div className="mouse">
          <BiMouse />
        </div>
      </section>
      <section className="form" id="joinus">
        <h1>Join us</h1>
        <form action="https://dark-kontinent.us5.list-manage.com/subscribe/post?u=abae96d686982d7197c2e107c&amp;id=bd453377f6" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" noValidate>
          <label>Monthly newsletter sign up</label>
          <div className="cover">
            <input type="email" placeholder="Enter your best email" name="EMAIL" id="mce-EMAIL" />
            <div id="mce-responses" className="clear">
            <div className="response" id="mce-error-response" style={{display:'none'}}></div>
            <div className="response" id="mce-success-response" style={{display:'none'}}></div>
            </div>
            <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_abae96d686982d7197c2e107c_bd453377f6" tabIndex={-1} value="" /></div>

            <button className="svg" type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" >
              {
                sendingEmail ? <Loader type="TailSpin" /> : <GrSend color="#DBD65C" />
              }
            </button>
          </div>
        </form>
        <form>
          <label>Get goodies by text message</label>
          <div className="cover">
            <input type="number" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Enter your favorite number" />
            <div className="svg" onClick={submitPhone}>
            {
              sendingPhone ? <Loader type="TailSpin" /> : <GrSend color="#DBD65C" />
            }
            </div>
          </div>
        </form>
        <p>Do you have an <span>account?</span></p>
        <h1>Connect with us</h1>
        <div className="social-links">
          <a target="_blank" href="https://twitter.com/kingwanshey">
            <RiTwitterLine />
          </a>
          <a target="_blank" href="https://instagram.com/kingwanshey">
            <RiInstagramLine />
          </a>
          <a target="_blank" href="https://open.spotify.com/artist/65zbd59QB792vcmQ3U4Zt3">
            <RiSpotifyLine />
          </a>
          <a target="_blank" href="https://tiktok.com/@kingwanshey88">
            <IoMusicalNotesOutline />
          </a>
          <a target="_blank" href="https://www.youtube.com/channel/UC7WAV7DpvbRykoRjv3oC2yw?sub_confirmation=1">
            <FiYoutube />
          </a>
          <a target="_blank" href="https://wanshey1.bandcamp.com/album/tribal-marks-2">
            <FiCompass />
          </a>
        </div>
      </section>
      <footer>
        <img src={whiteLogo} alt="dark kontinent logo" />
        <p>© Dark Kontinent 2021</p>
      </footer>
      <div className="chat">
        <a href="http://wa.me/+46736285627" target="_blank" rel="noopener noreferrer">
          <img src={chat} alt="chat with us" />
        </a>
        <p>Chat with us!</p>
      </div>
    </Main>
  );
}

export default App;
